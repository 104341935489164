.notifications {
  display: inline-block;
  background: transparent;
  position: fixed;
  min-height: 48px;
  margin-right: auto;
  margin-left: auto;
  width: auto;
  top: 1px;
  right: 0;
  left: 0;
  pointer-events: none;
  text-align: center;
  z-index: 29; }

.notification-list {
  list-style: none;
  text-align: center;
  display: inline-block;
  padding: 0; }

.notification-item {
  padding: 5px;
  pointer-events: all; }
  .notification-item_icon {
    float: left; }
  .notification-item_container {
    min-height: 48px;
    border-radius: 5px;
    display: inline-block;
    padding: 15px;
    cursor: pointer;
    border: 1px solid; }
  .notification-item_text {
    font-size: 1rem;
    margin-left: 10px;
    float: left; }
  .notification-item.notification-item--success .notification-item_container {
    background-color: #C6F8D9;
    border-color: #4AC579; }
  .notification-item.notification-item--success .notification-item_text {
    color: #4AC579; }
  .notification-item.notification-item--error .notification-item_container {
    background-color: #FCEAE7;
    border-color: #F16B55; }
  .notification-item.notification-item--error .notification-item_text {
    color: #F16B55; }
  .notification-item.notification-item--warning .notification-item_container {
    background-color: #FFF9E8;
    border-color: #FFCB25; }
  .notification-item.notification-item--warning .notification-item_text {
    color: #FFCB25; }
  .notification-item.notification-item--notif .notification-item_container {
    background-color: #CCFBFF;
    border-color: #00A6B4; }
  .notification-item.notification-item--notif .notification-item_text {
    color: #00A6B4; }
  .notification-item.notification-item--info .notification-item_container {
    background-color: #DFF8FF;
    border-color: #00B5E9; }
  .notification-item.notification-item--info .notification-item_text {
    color: #00B5E9; }
