html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  color: #868686;
  font-size: .85em;
  font-family: Lato, LatoMedium, LatoLatinMedium;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  background-color: #3d5160; }

input:focus {
  outline: none;
  border-color: #00a6b4; }

.login-page-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100%;
  position: relative;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  padding-top: 5%; }

.login-page-logo {
  text-align: center; }

.login-form_field {
  padding: 6px 0 6px 0; }
  .login-form_field__center {
    text-align: center; }

.login-form_input-text, .login-form_input-button {
  font-family: Lato, LatoMedium, LatoLatinMedium;
  padding: 13px 20px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  color: #5e5e5e;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  font-size: 1.3em; }
  .login-form_input-text:focus, .login-form_input-button:focus {
    border-color: #00A6B4;
    outline: none !important; }

.login-form_input-button {
  outline: none;
  background-color: #049fad;
  border: 0;
  color: #fff;
  cursor: pointer; }
  .login-form_input-button:disabled {
    pointer-events: none; }

.login-form_input-label {
  padding: 3px 5px;
  display: block;
  color: #c3c3c3; }

.account-search {
  margin: 0 5px 20px 5px;
  position: relative; }
  .account-search_input {
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 700;
    outline: none;
    padding: 25px 10px 25px 45px;
    border: 0; }
    .account-search_input:active {
      border: 0; }
  .account-search_icon {
    background: url(f58ed90ee5f238be2c9118487b26b1fa.svg) no-repeat center center;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -11px; }

@keyframes progress-bar-gradient {
  from {
    background-position: 0 0; }
  to {
    background-position: 70px 0; } }

.account-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 550px; }

.account-item {
  width: 100%;
  min-width: 250px;
  height: 70px;
  background: #ffffff;
  display: block;
  margin: 5px;
  cursor: pointer;
  position: relative;
  flex: 1;
  border: 0;
  text-align: left;
  color: #868686;
  font-size: .85em;
  font-family: Lato, LatoMedium, LatoLatinMedium;
  text-rendering: optimizeLegibility;
  font-weight: 400; }
  .account-item:hover, .account-item:active, .account-item:focus {
    color: #049fad;
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.4);
    border: 0;
    outline: none; }
  .account-item__processing {
    color: #fff;
    background-color: #48DB80;
    background-image: linear-gradient(45deg, #5CE791 25%, transparent 25%, transparent 50%, #5CE791 50%, #5CE791 75%, transparent 75%, transparent);
    animation: progress-bar-gradient 1s linear infinite;
    background-size: 70px 70px;
    pointer-events: none;
    border: 0;
    outline: none; }
  .account-item__disabled {
    background-color: #E2E2E2;
    color: rgba(255, 255, 255, 0.65);
    pointer-events: none; }
  .account-item_name {
    display: block;
    min-height: 100%;
    font-size: 15px;
    font-weight: bold;
    line-height: 70px;
    box-sizing: border-box;
    padding: 0 30px 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .account-item_arrow {
    background: url(72937c709839a8161033ff5828fe7f61.svg) no-repeat center center;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -7px; }
  .account-item_global, .account-item_global:hover, .account-item_global:visited .account-item_global:active {
    width: 540px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    background: #049fad; }
    .account-item_global .account-item_name, .account-item_global:hover .account-item_name, .account-item_global:visited .account-item_global:active .account-item_name {
      font-size: 20px;
      color: #fff; }
    .account-item_global .account-item_arrow, .account-item_global:hover .account-item_arrow, .account-item_global:visited .account-item_global:active .account-item_arrow {
      background: url(9d2451e89aed903a9c13fc00ea2246ef.svg) no-repeat center center; }
  .account-item_color-first {
    color: #ffc800; }
  .account-item_color-second {
    color: #fff; }

.account-item-caption {
  display: block;
  text-align: center; }
  .account-item-caption:focus {
    outline: none; }

.caption-above, .caption-above:visited, .caption-above:hover {
  font-size: 16px;
  margin-bottom: 10px;
  color: #00a5b3;
  text-decoration: none; }

.caption-below {
  margin-bottom: 15px;
  color: #839099; }

.column-fields-title {
  padding: 10px 10px 5px 10px;
  font-size: 20px;
  color: #fff; }

.column-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 550px; }

.column-fields_item {
  flex: 1;
  width: 100%;
  min-width: 250px;
  display: block;
  margin: 5px; }
