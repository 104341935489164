/*-------------------------- buttons -------------------------------*/
.button, .button:visited, .button:hover {
  display: inline-block;
  vertical-align: middle;
  background-color: #EDEDED;
  border: 0px solid;
  color: #000;
  font-size: 16px;
  line-height: 16px;
  padding: 10px 20px 12px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: none; }

.button:active {
  outline: none; }

.button:hover, .button__selected {
  background-color: #365CE3;
  color: #fff; }

.button__second-color, .button__second-color:visited {
  background-color: #00BB00;
  color: #fff; }

.button__second-color:hover {
  background-color: #00cb00; }

.button__third-color, .button__third-color:visited {
  background-color: #0048d6;
  color: #fff; }

.button__third-color:hover {
  background-color: #2473ff; }

.button__fourth-color, .button__fourth-color:visited {
  background-color: #ff9900;
  color: #fff; }

.button__fourth-color:hover {
  background-color: #ffa022; }

.button__light, .button__light:visited {
  background-color: #E5E5E5;
  border: 1px solid #b2b2b2;
  color: #4c4c4c; }

.button__light:hover {
  background-color: #F7F7F7;
  border: 1px solid #656565;
  color: #4c4c4c; }

.button__dark-bg, .button__dark-bg:visited {
  background-color: #b2b2b2;
  border: 1px solid #7f7f7f;
  color: #323232; }

.button__dark-bg:hover {
  background-color: #d8d8d8;
  color: #323232; }

.button__full {
  width: 100%;
  display: block; }

.button__large {
  width: 260px; }

.button__middle {
  width: 180px; }

.button__cube, .button__cube:hover, .button__cube:visited {
  padding: 6px 5px; }

.button__with-icon, .button__with-icon:hover, .button__with-icon:visited {
  padding: 6px 10px 6px 5px; }

.button-text {
  font-size: 16px;
  line-height: 25px;
  vertical-align: top; }

.button__down, .button__down:visited, .button__down:hover,
.button__up, .button__up:visited, .button__up:hover {
  background-color: #E5E5E5;
  border: 1px solid #b2b2b2;
  color: #4c4c4c;
  line-height: 22px;
  padding: 6px 5px 8px;
  text-align: center;
  width: 40px;
  font-size: 22px; }

.button__down:hover, .button__up:hover {
  background-color: #F7F7F7;
  border: 1px solid #656565; }

/*-------------------- end buttons --------------------------------*/
